<template>
  <tr>
    <td>{{ npp }}</td>
    <td>
      <strong>{{ group.id }}</strong>
    </td>
    <td>
      {{ group.sg_name }} <br /> <small>{{ group.sg_desc }}</small>
    </td>
    <td>
      <span v-html="$options.filters.toYesNo(group.is_active)"></span>
    </td>

    <td>
      <b-button
        size="sm"
        class="sm-override"
        variant="info"
        @click="setEdit(group)"
        ><i class="fas fa-pen"></i
      ></b-button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "GroupRow",
  components: {},
  props: ["group", "npp", "setEdit"],
  computed: {},
  methods: {}
};
</script>

<style lang="sass">
</style>
