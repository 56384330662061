<template>
  <div>
    <b-row>
      <b-col>
        <div class="slides_container">
          <b-carousel v-if="slides.length > 0 && is_desktop"
            id="carousel"
            v-model="slide"
            :interval="4000"
            fade
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="320"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <a v-for="slide in slides"
              :key="slide.id"
              :href="slide.sld_link"
            >
              <b-carousel-slide
                :caption="slide.sld_name"
                :text-html="slide.sld_desc"
                :img-src="slide.sld_image.file_url"
            ></b-carousel-slide>
          </a>
          </b-carousel>
        </div>
        <p v-if="page" v-html="page.page_text"></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainPage",
  components: {
  },
  computed: {
    ...mapGetters({
      page: "content/page",
      slides: "content/slides",
      is_desktop: "app/is_desktop",
    })
  },
  data: () => ({
    slide: 0,
    sliding: null
  }),
  created() {
    this.$store.dispatch("content/getPage", {page_slug: 'main'});
    this.$store.dispatch("content/getSlides");
  },
  watch: {
    // '$route'(to, from) {
    //   if(to.path.indexOf('/p/') !== -1) {
    //     this.$store.dispatch("content/getPage", {page_slug: this.$route.params.page_slug});
    //   }
    // }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
  }
};
</script>

<style lang="sass">
  .slides_container
    margin-bottom: 24px

  .fade-enter-active, .fade-leave-active
    transition: opacity .5s

  .fade-enter, .fade-leave-to
    opacity: 0

  .slide-fade-enter-active
    transition: all .3s ease

  .slide-fade-leave-active
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

  .slide-fade-enter, .slide-fade-leave-to
    transform: translateX(-20px)
    opacity: 0
</style>
