<template>
  <div class="note_wrapper">
    <div v-if="title"><strong>{{ title }}</strong></div>
    <div class="view_note" v-if="editMode">
      <div>
      <b-textarea
        id="note"
        size="sm"
        rows="4"
        max-rows="6"
        cols="60"
        autofocus
        v-model="local_note"
      />
      </div>
      <div class="actions">
        <b-button size="sm" variant="outline-danger" @click="cancelEdit">
          <i class="far fa-times-circle"></i>
        </b-button>
        <b-button size="sm" variant="success" @click="save">
          <i class="far fa-save"></i>
        </b-button>
      </div>
    </div>
    <div class="view_note" v-else>
      <div>{{ note }}</div>
      <div class="actions">
        <b-button size="sm" variant="outline-primary" @click="setEdit">
          <i class="fas fa-pen"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoteCard",
  props: ["note", "title", "saveNote"],
  data: () => ({
    editMode: false,
    local_note: ""
  }),
  methods: {
    setEdit() {
      this.editMode = true;
      this.local_note = this.note;
    },
    cancelEdit() {
      this.editMode = false;
      this.local_note = "";
    },
    save() {
      this.saveNote(this.local_note);
      this.cancelEdit();
    }
  }
};
</script>

<style lang="sass" scoped>
.note_wrapper
  padding: 8px
.view_note
  display: flex
  .actions
    margin-left: 8px
    border-left: 1px solid #e2e2e2
    padding: 0 8px
    display: flex
    flex-direction: column
    button
      margin-bottom: 8px
</style>
