<template>
  <div class="fixed-top">
    <div class="text-center top-line">
      <strong>+358 44 973 8044</strong> Tel. / Viber / WhatsApp<span v-if="is_desktop">, </span><span v-else><br /></span>
      <strong>+358 40 158 7462</strong> WhatsApp
    </div>

    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="/">
        Zakaz <strong>FI</strong>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/shops">Магазины</b-nav-item>
          <b-nav-item to="/u/cart">Ваш заказ <b-badge variant="info">{{ pl_quan }} / {{ (pl_sum).toFixed(2) }} EUR / {{ (pl_weight).toFixed(2) }} кг.</b-badge></b-nav-item>

          <b-nav-item-dropdown v-if="isLoggedIn && role==='user'" text="Мой кабинет" right>
            <b-dropdown-item to="/u/orders">Заказы</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="isLoggedIn && role==='admin'" text="Операции" right>
            <b-dropdown-item to="/a/orders">Все заказы</b-dropdown-item>
            <b-dropdown-item to="/a/asm_lists">Все сборки</b-dropdown-item>
            <div class="dropdown-divider"></div>
            <b-dropdown-item to="/a/groups">Все группы</b-dropdown-item>
            <b-dropdown-item to="/a/shops">Магазины</b-dropdown-item>
            <div class="dropdown-divider"></div>
            <b-dropdown-item to="/a/delivery">Доставка</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Статы" v-if="isLoggedIn && role==='admin'" right>
            <b-dropdown-item href="/a/sup_stats">Поставщики</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="isLoggedIn && role==='admin'" text="Контент" right>
            <b-dropdown-item to="/a/pages">Все страницы</b-dropdown-item>
            <b-dropdown-item to="/a/slides">Все слайды</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="isLoggedIn && role==='admin'" text="Финансы" right>
            <b-dropdown-item to="/a/payments">Платежи клиентов</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
        <b-navbar-nav>
          <div class="ml-3" v-if="!isLoggedIn">
            <b-nav-item class="login_button_container" to="/u/login">
              <span class="btn btn-sm btn-primary">Войти <i class="fas fa-sign-in-alt"></i></span>
            </b-nav-item>
          </div>
          <div class="ml-3" v-else>
            <div class="logout">
              <div class="username"><i class="fas fa-user-alt"></i> {{ username }}</div>
              <button class="btn btn-sm btn-warning" @click="logout">Выйти <i class="fas fa-sign-out-alt"></i></button>
            </div>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {
  },
  props: [],
  data: () => ({}),
  computed: {
    ...mapGetters({
      loading: "loader/loading",
      pl_weight: "products_links/get_total_weight",
      pl_quan: "products_links/get_total_quan",
      pl_sum: "products_links/get_total_sum_with_vat",
      isLoggedIn: "user/isLoggedIn",
      role: "user/role",
      username: "user/username",
      is_desktop: "app/is_desktop"
    }),
    total_weight() {
      return (this.weight).toFixed(2);
    },
    total_quan() {
      return this.quan;
    },
    total_sum() {
      return (this.sum).toFixed(2);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    }
  },
  created() {}
};
</script>

<style lang="sass">
nav
  margin-bottom: 20px
.logout
  display: flex
  button
    margin-left: 8px
  .username
    padding-top: 4px
    color: #fff

</style>
