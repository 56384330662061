<template>
  <b-row v-if="!loading">
    <b-col>
      <PageTitle
        :title="title"
        back="true"
        :actions="getActions()"
        :do_action="doAction"
        :title_message="title_message"
        :title_id="title_id"
        mode="u"
        />
      <b-row>
        <b-col md="3">
          <div class="order_head">
            <ul>
              <li>
                <div class="header_label">Статус:</div>
                <b-badge :variant="order.status.st_color">{{
                  order.status.st_name
                }}</b-badge>
              </li>
              <li>
                <div class="header_label">Создан:</div>{{ order.created_at }}
              </li>
              <li>
                <div class="sums space_above space_below">
                  <ul>
                    <li>
                      <div class="header_label">Доставка:</div>
                      <strong>{{ order.dlv_price | toCommas }}</strong>
                    </li>
                    <li>
                      <div class="header_label">Сумма:</div>
                      <strong>{{ order.raw_sum | toCommas }}</strong>
                    </li>
                    <li>
                      <div class="header_label">Сумма + доставка:</div>
                      <strong>{{ delivery_raw | toCommas }}</strong>
                    </li>
                    <li class="border_dark_above">
                      <div class="header_label">Сумма(Ф):</div>
                      <strong>{{ order.raw_sum_fct | toCommas }}</strong>
                    </li>
                    <li>
                      <div class="header_label">К оплате(Ф):</div>
                      <strong>{{ delivery }}</strong> <small>(Факт + доставка)</small>
                    </li>
                  </ul>
                </div>

              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="4">
          <AddressCard
            v-if="order.address"
            :address="order.address"
            title="Доставка:"
          />
          <NoteCard title="Комментарий:" :note="order.note" />
        </b-col>
        <b-col>
          <!-- <div class="toolbar right t-row space_below">
            <div class="t-cell">
              <div class="card" v-if="order && order.is_paid">
                <div class="card-body">
                  Оплачено: {{ order.expense.exp_summ }} EUR
                  <p class="card-text">{{ order.expense.exp_note }}</p>
                </div>
              </div>
              <div class="card" v-if="order && !order.is_paid && order.payment_note">
                <div class="card-body">
                  <strong>Внимание!</strong> Оплата была отменена<br>
                  <p class="card-text">{{ order.payment_note }}</p>
                </div>
              </div>
            </div>
            <div class="t-cell" v-if="!order.is_paid && !payment_lock">
              <b-button variant="outline-success" @click="showPaytrailModal">
                <i class="fas fa-money-check"></i> Оплатить заказ
              </b-button>
            </div>
            <div class="t-cell" v-if="payment_lock">
              <b-alert variant="danger" show>
                <strong>Внимание!</strong> Оплата заказов временно отключена. Вы сможете оплатить заказ позже.
              </b-alert>
            </div>
          </div> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="table table-sm table-hover table-bordered table-striped data-grid">
            <thead class="thead-dark">
              <tr>
                <th width="40">нпп</th>
                <th width="50">ID #</th>
                <th width="120"></th>
                <th colspan="2">Название</th>
                <th width="160">Артикул</th>
                <th width="70">Вес, шт.</th>
                <th width="70">Кол-во</th>
                <th width="70">Вес</th>
                <th width="70">КолФ.</th>
                <th width="70">ВесФ.</th>
                <th width="70">Цена</th>
                <th width="80">Всего</th>
                <th width="80">ВсегоФ.</th>
                <th width="100">Цена c НДС</th>
                <th width="100">Всего с НДС</th>
                <th width="40"></th>
              </tr>
            </thead>
            <tbody>
              <ProductRow
                key_name="product"
                v-for="(prod, idx) in products"
                :key="prod.id"
                :idx="idx"
                :prod="prod"
                :npp="products.length - idx"
                :can_delete="can_delete"
                :delPosition="delPosition"
              />
            </tbody>
            <thead class="thead-light">
              <tr>
                <th colspan="6"></th>
                <th>{{ order.ttl_pos }}</th>
                <th>{{ order.ttl_quan }}</th>
                <th>{{ order.ttl_wgt }}</th>
                <th class="fact">{{ order.ttl_quan_fct }}</th>
                <th class="fact">{{ order.ttl_wgt_fct }}</th>
                <th></th>
                <th>{{ order.raw_sum | toCommas }}</th>
                <th class="fact">{{ order.raw_sum_fct | toCommas }}</th>
                <th></th>
                <th>{{ order.full_sum | toCommas }}</th>
                <th></th>
              </tr>
            </thead>
          </table>
        </b-col>
      </b-row>
    </b-col>
    <!-- <PaytrailModal
      ref="paytrail_modal"
      title="Оплата через Paytrail"
      mode="u"
      :raw_sum_fct="order.raw_sum_fct"
      :raw_sum="order.raw_sum"
      :delivery="delivery"
      :delivery_raw="delivery_raw"
      :closePaytrailModal="closePaytrailModal"
      :order_id="order.id"
    /> -->
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

// import Datepicker from "vuejs-datepicker";
// import { ru } from "vuejs-datepicker/dist/locale";
// import { ISO_Long_To_Short } from "@/services/date_tools";

import AddressCard from "@/components/user/addresses/AddressCard";
import NoteCard from "./NoteCard";
import ProductRow from "./ProductRow";
import PageTitle from "@/components/generic/backoffice/PageTitle";

// import PaytrailModal from "@/components/finances/PaytrailModal";

export default {
  name: "Order",
  components: {
    AddressCard,
    NoteCard,
    PageTitle,
    ProductRow
    // PaytrailModal
  },
  data: () => ({
    loading: true,
    title_message: "",
    title_id: null
  }),
  computed: {
    ...mapGetters({
      order: "orders/order",
      payment_lock: "settings/payment_lock"
    }),
    delivery() {
      return (
        Number(this.order.dlv_price) + Number(this.order.raw_sum_fct)
      ).toFixed(2);
    },
    delivery_raw() {
      return (
        Number(this.order.dlv_price) + Number(this.order.raw_sum)
      ).toFixed(2);
    },
    can_delete() {
      if (this.order.status.id === 1) return true;
      return false;
    },
    title() {
      return `Заказ #${this.$route.params.id}`;
    },
    products() {
      return this.order.products ? this.order.products : [];
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.title_message = "";
        this.title_id = null;
      }
      if (this.$route.params.id) {
        this.$store.dispatch("orders/getOrder", {
          id: this.$route.params.id,
          mode: "u"
        });
      }
    },
  },
  created() {
    this.loading = true;
    if (this.$route.params.id) {
      this.$store.dispatch("orders/getOrder", {
        id: this.$route.params.id,
        mode: "u"
      }).then(() => this.loading = false);
    }
  },
  methods: {
    getActions() {
    if (this.order && this.order.is_paid === false && this.order.status.id === 1) {
        return [{
            id: "copy",
            button_title: "Скопировать заказ",
            button_color: "outline-primary",
            confirmation: false
          }, {
          id: "delete",
          button_title: "Удалить заказ",
          button_color: "outline-danger",
          confirmation: true,
          mode: 'u'
        }]
      }
      return [];
    },
    doAction(id, mode) {
      this.$store.dispatch("orders/doAction", { action: id, order_id: this.order.id, mode })
        .then((data) => {
          if (id === "copy") {
            this.title_message = "Заказ скопирован";
            this.title_id = data.order_id;
          }
        });
    },
    delPosition(id, origin) {
      this.$store.dispatch("orders/delPosition", { id: id, origin });
    },

    // Paytrail
    // closePaytrailModal() {
    //   this.$refs.paytrail_modal.$refs.paytrail_modal.hide();
    // },
    // showPaytrailModal() {
    //   this.$refs.paytrail_modal.$refs.paytrail_modal.show();
    // },

  }
};

</script>

<style lang="sass">
.order_head
  padding: 5px
  font-size: 1.1em
  ul
    li
      display: flex
      .badge
        font-size: 100%
.header_label
  width: 120px
</style>
