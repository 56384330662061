<template>
  <b-row>
    <b-col>
      <PageTitle title="Все группы" />
      <b-row>
        <b-col md="6">
          <GroupsList :groups="groups" :setEdit="setEdit" />
        </b-col>
        <GroupForm
          :active_group="active_group"
          :resetActive="resetActive"
          :checkUnique="checkUnique"
        />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import GroupForm from "./GroupForm";
import GroupsList from "./GroupsList";
import PageTitle from "@/components/generic/backoffice/PageTitle";

export default {
  name: "AdmGroups",
  components: {
    GroupForm,
    GroupsList,
    PageTitle
  },
  data: () => ({
    active_group: {}
  }),
  computed: {
    groups() {
      return this.$store.getters["shop_groups/adm_groups"];
    }
  },
  watch: {},
  created() {
    this.getGroups();
  },
  methods: {
    checkUnique(id, sg_name) {
      const groups = this.groups.filter(group => group.sg_name === sg_name);
      let fl = false;
      if (id) {
        if (groups.length === 1 && id === groups[0].id) fl = true;
        if (groups.length === 0) fl = true;
      } else {
        if (groups.length === 0) fl = true;
      }

      return fl;
    },
    resetActive() {
      this.active_group = {};
    },
    setEdit(group) {
      this.active_group = group;
    },
    getGroups() {
      this.$store.dispatch("shop_groups/getGroups", {
        origin: "adm",
        mode: "a"
      });
    }

  }
};
</script>

<style lang="sass">

</style>
