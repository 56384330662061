import Api from "@/services/general";

const state = {
  adm: [],
  groups: []
};

const getters = {
  adm_groups: state => state.adm,
  groups: state => state.groups,
  obj_groups: state => state.groups.reduce((acc, grp) => {
    if (!acc[grp.id]) acc[grp.id] = {};
    acc[grp.id] = grp;
    return acc;
  }, {}),
};

const actions = {
  async createGroup(ctx, payload) {
    const pl = { ...payload };
    delete pl.origin;
    let res = null;
    if (payload.id !== null) {
      delete pl.id;
      res = await Api.setData("a/group", payload.id, pl);
    } else {
      res = await Api.setData("a/group", null, pl);
    }
    this.commit("shop_groups/updateGroups", {
      data: res.data.group,
      origin: payload.origin
    });
  },
  async getGroups(ctx, payload) {
    const url = `${payload.mode}/groups`;
    const res = await Api.getData(url);
    this.commit("shop_groups/setGroups", { data: res.data, origin: payload.origin });
  }
};

const mutations = {
  setGroups(state, payload) {
    state[payload.origin] = payload.data;
  },
  updateGroups(state, payload) {
    const groups = state[payload.origin].filter(
      group => group.id !== payload.data.id
    );
    state[payload.origin] = [payload.data, ...groups];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
