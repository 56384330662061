<template>
  <tr>
    <td>{{ npp }}</td>
    <td>{{ prod.id }}</td>
    <td>
      <div class="image">
        <img v-if="prod.product.prod_image && prod.product.prod_image.file_url" :src="prod.product.prod_image.file_url" :alt="prod.product.prod_name" />
        <div v-else class="placeholder">нет картинки</div>
      </div>
    </td>
    <td>
      {{ prod.product.prod_name }}<br />
      <small>Магазин: {{ prod.product.shop && prod.product.shop.shop_name }}</small><br />
      <small v-if="prod.product.prod_link">
        <a :href="prod.product.prod_link" target="_blank">
          {{ prod.product.prod_link }}
        </a>
      </small>
    </td>
    <td>
      {{ prod.product.note }}
    </td>

    <td>{{ prod.product.mnf_code }}</td>
    <td>{{ prod.product.weight || "0.00" }}</td>
    <td class="want">{{ prod.quan }}</td>
    <td class="want">{{ row_weight }}</td>
    <td class="fact">{{ prod.quan_fct }}</td>
    <td class="fact">{{ row_weight_fct }}</td>
    <td>
      {{ prod.price }}
    </td>
    <td class="want">{{ total }}</td>
    <td class="fact">{{ total_fct }}</td>
    <td>{{ prod.price_with_vat }}</td>
    <td>{{ total_with_vat }}</td>
    <td width="40">
      <button v-if="can_delete"
        class="btn btn-sm sm-override btn-danger"
        @click="delPosition(prod.id, key_name)"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "Position",
  props: [
    "npp",
    "is_admin",
    "prod",
    "key_name",
    "delPosition",
    "changeQuan",
    "can_delete",
    "can_change_fact",
    "updateQuanFct"
  ],
  data: () => ({
    quan_fct: 0,
    save_enabled: false
  }),
  created() {
    if (this.prod.quan_fct) this.quan_fct = this.prod.quan_fct;
  },
  computed: {
    row_weight() {
      return Number(this.prod.quan) > 0 && Number(this.prod[this.key_name].weight) > 0
        ? (Number(this.prod.quan) * Number(this.prod[this.key_name].weight)).toFixed(2)
        : "0.0";
    },
    row_weight_fct() {
      return Number(this.prod.quan_fct) > 0 && Number(this.prod[this.key_name].weight) > 0
        ? (Number(this.prod.quan_fct) * Number(this.prod[this.key_name].weight)).toFixed(2)
        : "0.0";
    },
    total() {
      return (this.prod.quan * this.prod.price).toFixed(2);
    },
    total_fct() {
      return (this.prod.quan_fct * this.prod.price).toFixed(2);
    },
    total_with_vat() {
      return (this.prod.quan * this.prod.price_with_vat).toFixed(2);
    },
    vat_rate() {
      return this.prod.vat_rate ? `${this.prod.vat_rate}%` : "н/д";
    },
    vat_sum() {
      return (this.prod.price_with_vat - this.prod.price).toFixed(2);
    },
  },
  watch: {
    quan_fct: function(newVal) {
      this.save_enabled = Number(newVal) !== this.prod.quan_fct ? true : false;
      this.quan_fct = Number(newVal);
    }
  },
  methods: {
    discardEdit() {
      if (this.prod.quan_fct) {
        this.quan_fct = this.prod.quan_fct;
      } else {
        this.quan_fct = 0;
      }
      this.save_enabled = false;
    },
    saveQuan() {
      this.updateQuanFct(this.prod.id, this.quan_fct);
      this.save_enabled = false;
    }
  }
};
</script>

<style lang="sass">
.image
  height: 60px
  display: flex
  justify-content: center
  align-items: center
  img
    max-width: 60px
    max-height: 60px

</style>
