<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th>Название/Описание</th>
        <th width="250">Группа</th>
        <th width="150">Ссылка</th>
        <th width="30">Акт?</th>

        <th width="36"></th>
      </tr>
    </thead>
    <tbody>
      <ShopRow
        v-for="(shop, idx) in shops"
        :key="shop.id"
        :idx="idx"
        :shop="shop"
        :npp="shops.length - idx"
        :setEdit="setEdit"
        />
    </tbody>
  </table>
</template>

<script>
import ShopRow from "./ShopRow";

export default {
  name: "ShopsList",
  components: {
    ShopRow
  },
  props: ["shops", "setEdit"],
  computed: {},
  methods: {}
};
</script>
