<template>
  <b-row>
    <b-col>
      <PageTitle title="Все магазины" />
      <b-row>
        <b-col md="7">
          <ShopsList :shops="shops" :setEdit="setEdit" />
        </b-col>
        <ShopForm
          :groups="groups"
          :active_shop="active_shop"
          :resetActive="resetActive"
          :checkUnique="checkUnique"
        />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ShopForm from "./ShopForm";
import ShopsList from "./ShopsList";
import PageTitle from "@/components/generic/backoffice/PageTitle";

export default {
  name: "AdmShops",
  components: {
    ShopForm,
    ShopsList,
    PageTitle
  },
  data: () => ({
    active_shop: {}
  }),
  computed: {
    shops() {
      return this.$store.getters["shops/adm_shops"];
    },
    groups() {
      return this.$store.getters["shop_groups/adm_groups"];
    }
  },
  watch: {},
  created() {
    this.getShops();
    this.getGroups();
  },
  methods: {
    checkUnique(id, shop_name) {
      const shops = this.shops.filter(shop => shop.shop_name === shop_name);
      let fl = false;
      if (id) {
        if (shops.length === 1 && id === shops[0].id) fl = true;
      } else {
        if (shops.length === 0) fl = true;
      }

      return fl;
    },
    resetActive() {
      this.active_shop = {};
    },
    setEdit(shop) {
      this.active_shop = shop;
    },
    getShops() {
      this.$store.dispatch("shops/getShops", {
        origin: "adm",
        mode: "a"
      });
    },
    getGroups() {
      this.$store.dispatch("shop_groups/getGroups", {
        origin: "adm",
        mode: "a"
      });
    }
  }
};
</script>

<style lang="sass">

</style>
