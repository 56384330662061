import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import vSelect from "vue-select";
import BootstrapVue from "bootstrap-vue";
import UUID from "vue-uuid";

import CKEditor from "@ckeditor/ckeditor5-vue2";

import VueHtmlToPaper from "vue-html-to-paper";
import ImageUploader from "vue-image-upload-resize";

import VueSocketIO from "vue-socket.io";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "./styles/print.css"
  ]
};

Vue.use(VueHtmlToPaper, options);

Vue.use(VueAxios, axios);
Vue.use(UUID);
Vue.use(BootstrapVue);
Vue.use(ImageUploader);

Vue.use(CKEditor);

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/src/scss/vue-select.scss";
import "@/styles/styler.sass";

Vue.filter("toCommas", function(value) {
  return value ? String(Number(value).toFixed(2)).replace(".", ",") : "0,00";
});

Vue.filter("toYesNo", function(value) {
  return value
    ? '<span class="text-success"><strong>Да</strong></span>'
    : '<span class="text-danger"><strong>Нет</strong></span>';
});

// const debug = process.env.NODE_ENV !== "production";
// const ws_url = process.env.VUE_APP_ROOT_WS !== undefined
//                 ? process.env.VUE_APP_ROOT_WS
//                 : "ws://localhost:8000";

// Vue.use(new VueSocketIO({
//   debug: debug,
//   connection: "ws://localhost:8000", //ws_url,
//   vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//   },
//   options: {  path: "/ws/orders/",
//               transports: ["websocket"]
//               // cors: {
//               //   origin: "process.env.VUE_APP_ROOT_API",
//               //   methods: ["GET", "POST"],
//               //   // allowedHeaders: ["my-custom-header"],
//               //   credentials: false
//               // }
//             }
// }))

new Vue({
  router,
  store,
  beforeCreate() {
    store.dispatch("settings/getSettings").then(() => {});
    store.dispatch("content/getPages").then(() => {});
    // store.dispatch("content/getSlides").then(() => {});
    store.dispatch("user/checkUser").then(() => {});
  },
  render: h => h(App)
}).$mount("#app");
