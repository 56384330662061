<template>
  <table
    class="table table-sm table-hover table-bordered table-striped data-grid"
  >
    <thead class="thead-dark">
      <tr>
        <th width="40">нпп</th>
        <th width="50">ID #</th>
        <th>Название/Описание</th>
        <th width="30">Акт?</th>

        <th width="36"></th>
      </tr>
    </thead>
    <tbody>
      <GroupRow
        v-for="(group, idx) in groups"
        :key="group.id"
        :idx="idx"
        :group="group"
        :npp="groups.length - idx"
        :setEdit="setEdit"
        />
    </tbody>
  </table>
</template>

<script>
import GroupRow from "./GroupRow";

export default {
  name: "GroupsList",
  components: {
    GroupRow
  },
  props: ["groups", "setEdit"],
  computed: {},
  methods: {}
};
</script>
