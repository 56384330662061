import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

// import Catalog from "@/components/catalog/Catalog.vue";
// import Items from "@/components/catalog/Items.vue";
// import ItemCard from "@/components/catalog/ItemCard.vue";
// import Cart from "@/components/catalog/Cart.vue";
import CartLink from "@/components/catalog/CartLink.vue";

import MainPage from "@/components/content/MainPage.vue";

import Login from "@/components/generic/Login.vue";
import Orders from "@/components/orders/Orders.vue";
import Order from "@/components/orders/Order.vue";

import AdmSupStats from "@/components/stats/AdmSupStats.vue";

import AsmLists from "@/components/orders/backoffice/asm/AsmLists.vue";
import AsmListView from "@/components/orders/backoffice/asm/AsmListView.vue";

// import AdmGroups from "@/components/groups/backoffice/AdmGroups.vue";

import Shops from "@/components/content/Shops.vue";
import AdmShops from "@/components/shops/backoffice/AdmShops.vue";
import AdmGroups from "@/components/shop_groups/backoffice/AdmGroups.vue";

import AdmOrder from "@/components/orders/backoffice/AdmOrder.vue";
import AdmOrders from "@/components/orders/backoffice/AdmOrders.vue";
import AdmDelivery from "@/components/delivery/backoffice/AdmDelivery.vue";

import OrderPaySplash from "@/components/orders/payments/OrderPaySplash.vue";
import OrderPayment from "@/components/orders/payments/OrderPayment.vue";
import CartPayment from "@/components/orders/payments/CartPayment.vue";

import AdmPayments from "@/components/finances/payments/AdmPayments.vue";

import AdmSlides from "@/components/content/backoffice/AdmSlides.vue";

import AdmPages from "@/components/content/backoffice/AdmPages.vue";
// import AdmUpdater from "@/components/content/backoffice/services/AdmUpdater.vue";
import Page from "@/components/content/Page.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/p/:page_slug?",
      name: "page",
      component: Page
    },
    {
      path: "/",
      name: "main_page",
      component: MainPage
    },
    // {
    //   path: "/",
    //   name: "catalog",
    //   component: Catalog,
    //   redirect: {
    //     name: "catalog.items"
    //   },
    //   children: [
    //     {
    //       path: "/:cat_id?",
    //       name: "catalog.items",
    //       component: Items
    //     },
    //     {
    //       path: "/i/:item_id?",
    //       name: "catalog.item",
    //       component: ItemCard
    //     }
    //   ]
    // },

    // {
    //   path: "/:cat_id?",
    //   name: "catalog",
    //   component: Catalog
    // },
    // {
    //   path: "/i/:item_id?",
    //   name: "item",
    //   component: ItemCard
    // },
    // {
    //   path: "/u/cart",
    //   name: "cart",
    //   component: Cart
    // },
    {
      path: "/u/cart",
      name: "cart",
      component: CartLink
    },
    {
      path: "/u/orders",
      name: "orders",
      component: Orders,
      meta: { requiresAuth: true, roles: ["admin", "operator", "user"] }
    },
    {
      path: "/u/order/:id",
      name: "order",
      component: Order,
      meta: { requiresAuth: true, roles: ["admin", "operator", "user"] }
    },
    {
      path: "/u/pay_order/:id",
      name: "pay_order",
      component: OrderPaySplash,
      meta: { requiresAuth: true, roles: ["admin", "operator", "user"] }
    },

//////////////////////////////////////////
    // CART - PAYTRAIL RETURN CATCHER!!!!
    {
      path: "/u/cart_payment/:id/:result",
      name: "cart_payment",
      component: CartPayment,
      meta: { requiresAuth: true, roles: ["admin", "operator", "user"] }
    },
    // CART - PAYTRAIL RETURN CATCHER!!!!
//////////////////////////////////////////

//////////////////////////////////////////
    // PAYTRAIL RETURN CATCHER!!!!
    {
      path: "/:mode/order/:id/:result",
      name: "order_payment",
      component: OrderPayment,
      meta: { requiresAuth: true, roles: ["admin", "operator", "user"] }
    },
    // PAYTRAIL RETURN CATCHER!!!!
//////////////////////////////////////////

    {
      path: "/a/sup_stats",
      name: "adm_sup_stats",
      component: AdmSupStats,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/payments",
      name: "adm_payments",
      component: AdmPayments,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/slides",
      name: "adm_slides",
      component: AdmSlides,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/pages",
      name: "adm_pages",
      component: AdmPages,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/delivery",
      name: "adm_delivery",
      component: AdmDelivery,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/orders",
      name: "adm_orders",
      component: AdmOrders,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/order/:id",
      name: "adm_order",
      component: AdmOrder,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/asm_lists",
      name: "asm_lists",
      component: AsmLists,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/asm_list/:id",
      name: "asm_list",
      component: AsmListView,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/a/groups",
      name: "adm_groups",
      component: AdmGroups,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/shops",
      name: "shops",
      component: Shops,
    },
    {
      path: "/a/shops",
      name: "adm_shops",
      component: AdmShops,
      meta: { requiresAuth: true, roles: ["admin"] }
    },
    {
      path: "/u/login",
      name: "login",
      component: Login
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.path === "/logout") {
    store.dispatch("user/logout");
    return next({
      path: "/login"
    });
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //TODO: REWRITE!!!!!
    store.commit("loader/RESET_LOADING");
    const isLoggedIn = store.getters["user/isLoggedIn"];
    let hasAccess = true;
    let role = store.getters["user/role"];
    if (!role) {
      store.dispatch("user/recreateToken").then(user_role => {
        role = user_role;
        if (to.meta.roles) {
          hasAccess = to.meta.roles.includes(role) ? true : false;
        }
        if (!hasAccess && to.path !== "/") {
          return next({
            path: "/"
          });
        }
        next();
      });
    }
    if (!isLoggedIn) {
      return next({
        path: "/login",
        query: { returnUrl: to.path }
      });
    } else if (!hasAccess && to.path !== "/") {
      return next({
        path: "/"
      });
    }
  }

  next();
});

export default router;
