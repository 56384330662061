<template>
  <b-col>
    <WidgetTitle :title="title" />
    <b-row class="mb-2">
      <b-col>
        <Selector
          label="Группа"
          :list="groups"
          mandatory="true"
          value_label="sg_name"
          :selected="group_id"
          :select-entity="selectGroup"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="shop_name">Название:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="shop_name" size="sm" v-model="shop_name" />
        <small class="text-danger" v-if="shop_name !== '' && !unique">
          Не уникальное название магизина.
        </small>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="shop_desc">Описание:</label>
      </b-col>
      <b-col sm="8">
        <b-textarea
          id="shop_desc"
          size="sm"
          rows="4"
          max-rows="6"
          v-model="shop_desc"
        ></b-textarea>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="shop_link">Ссылка:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="shop_link" size="sm" v-model="shop_link" />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col sm="4">
        <label for="is_active">Активен?:</label>
      </b-col>
      <b-col sm="8">
        <b-form-checkbox v-model="is_active" name="is_active" />
      </b-col>
    </b-row>
    <div class="toolbar right t-row border-top pt-2">
      <b-button
        variant="info"
        @click="newShop"
      >
        Новый магазин
      </b-button>
      <b-button
        :disabled="isDisabled"
        variant="primary"
        @click="createShop"
      >
        Сохранить
      </b-button>
    </div>
  </b-col>
</template>

<script>
import Selector from "@/components/generic/backoffice/Selector";
import WidgetTitle from "@/components/generic/backoffice/WidgetTitle";

export default {
  name: "ShopForm",
  components: {
    WidgetTitle,
    Selector
  },
  props: ["active_shop", "groups", "resetActive", "checkUnique"],
  data: () => ({
    id: null,
    shop_name: "",
    shop_desc: "",
    shop_link: "",
    group_id: null,
    is_active: false,
    unique: false
  }),
  computed: {
    isDisabled() {
      return this.shop_name === "" || !this.unique;
    },
    title() {
      return !this.id ? "Новый магазин" : "Редактирование магазина";
    }
  },
  watch: {
    shop_name(newVal) {
      this.unique = this.checkUnique(this.id, newVal);
    },
    active_shop(newVal, oldVal) {
      if (!oldVal || newVal.id !== oldVal.id) {
        this.id = newVal.id;
        this.shop_name = newVal.shop_name;
        this.shop_desc = newVal.shop_desc;
        this.shop_link = newVal.shop_link;
        this.group_id  = newVal.group_id;
        this.is_active = newVal.is_active;
      }
    }
  },
  created() {},
  methods: {
    selectGroup(group_id) {
      this.group_id = group_id;
    },
    newShop() {
      this.resetActive();
      this.resetForm();
    },
    resetForm() {
      this.id = null;
      this.shop_name = "";
      this.shop_desc = "";
      this.shop_link = "";
      this.group_id  = null;
      this.is_active = false;
    },
    createShop() {
      const x_id = this.id ? { id: this.id } : {};
      this.$store
        .dispatch("shops/createShop", {
          ...x_id,
          origin: "adm",
          shop_name: this.shop_name,
          shop_desc: this.shop_desc,
          shop_link: this.shop_link,
          group_id: this.group_id,
          is_active: this.is_active
        })
        .then(status => {
          this.resetForm();
        });
    }
  }
};
</script>
