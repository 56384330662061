<template>
  <div>
    <b-row>
      <b-col>
        <PageTitle title="Магазины" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div v-for="group in Object.keys(shops)"
          :key="group"
          :group="group">
          <div class="group_row">
            <h3>{{ groups[group].sg_name }}</h3>
            <div class="group_desc" v-if="groups[group].sg_desc">{{ groups[group].sg_desc }}</div>
          </div>
          <div class="shop_row"
            v-for="shop in shops[group]"
            :key="shop.id"
            :shop="shop"
            >
              <strong>{{ shop.shop_name }}</strong> | <a :href="shop.shop_link" target="_blank" v-if="shop.shop_link !== ''">{{
        shop.shop_link
      }}</a>
              <div><small>{{ shop.shop_desc }}</small></div>
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageTitle from "@/components/generic/backoffice/PageTitle";

import { mapGetters } from "vuex";

export default {
  name: "Shops",
  components: {
    PageTitle
  },
  computed: {
    ...mapGetters({
      shops: "shops/grouped_shops",
      groups: "shop_groups/obj_groups",
    })
  },
  data: () => ({
  }),
  created() {
    this.$store.dispatch("shops/getShops", { mode: "u", origin: "cart" });
    this.$store.dispatch("shop_groups/getGroups", {
      origin: "groups",
      mode: "u"
    });
  },
  watch: {
  },
  methods: {
  }
};
</script>

<style lang="sass">
.shop_row
  margin-left: 4px
  margin-bottom: 4px
  padding: 4px
  // border-bottom: #d5d5d5 dotted 1px
  font-size: 1.1em
  border-left: 2px solid #fff
  &:hover
    border-left: 2px solid darken(#3eb141, 5%)
    background-color: #f8f8f8
.group_row
  h3
    margin-bottom: 0
</style>
